import dotenv from "dotenv";

dotenv.config();

export default async function auth({ next, router }) {
  if (!$cookies.get("isLogined")) {
    window.location.href = "/login";
  }

  return next();
}
