<template>
  <footer class="footer">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <nav></nav>
      <div class="copyright">
        &copy; Service
        <i class="fa fa-heart heart"></i> by
        <a :href="hostname" target="_blank" rel="noopener">{{ hostname }}</a
        >.
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      hostname: window.location.hostname,
    };
  },
};
</script>
<style></style>
