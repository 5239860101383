<template>
  <div class="user d-flex">
    <div class="photo">
      <img src="/static/img/guest-icon-png-6.jpg" alt="avatar" />
    </div>
    <div class="info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click.stop="toggleMenu"
        href="#"
      >
        <span>
          {{ title }}
        </span>
      </a>
    </div>
  </div>
</template>
<script>
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";

export default {
  components: {
    [CollapseTransition.name]: CollapseTransition,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isClosed: true,
    };
  },
  methods: {
    toggleMenu() {
      this.isClosed = !this.isClosed;
    },
  },
  mounted() {},
};
</script>
<style>
.collapsed {
  transition: opacity 1s;
}
.sidebar-mini .info {
  display: none !important;
}
.sidebar .logo {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.sidebar .logo:before,
.bootstrap-navbar .logo:before {
  width: 0;
}
.sidebar .user {
  padding-bottom: 10px;
  margin-top: 10px;
}
</style>
