import Vue from "vue";
import Router from "vue-router";

import SideBar from "@/layouts/SideBar.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";

import auth from "../middleware/auth";
import log from "../middleware/log";

Vue.use(Router);

const router = new Router({
  mode: "history",
  linkActiveClass: "active",

  routes: [
    {
      path: "/",
      redirect: "/asg",
    },
    {
      path: "/login",
      component: AuthLayout,
      children: [
        {
          path: "",
          name: "login",
          component: () => import("@/pages/login"),
          meta: {
            middleware: log,
          },
        },
      ],
    },

    {
      path: "",
      component: SideBar,
      children: [
        {
          path: "/asg",
          name: "ALLSLOTGAME168.COM",
          component: () => import("@/pages/asg"),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          path: "/vip",
          name: "ALLSLOTVIP.COM",
          component: () => import("@/pages/vip"),
          meta: {
            middleware: [auth, log],
          },
        },

        {
          path: "/win",
          name: "ALLSLOTWIN.COM",
          component: () => import("@/pages/win"),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          path: "/tuktuk888",
          name: "TUKTUK888",
          component: () => import("@/pages/tuktuk888"),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          path: "/oki888",
          name: "OKI888",
          component: () => import("@/pages/oki888"),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          path: "/ambet168",
          name: "AMBET168",
          component: () => import("@/pages/ambet168"),
          meta: {
            middleware: [auth, log],
          },
        },
      ],
    },
    {
      path: "/*",
      component: AuthLayout,
      children: [
        {
          path: "",
          name: "notfound",
          component: () => import("@/pages/notfound"),
        },
      ],
    },
  ],
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;
  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }
  return next();
});

export default router;
