<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <button
        type="button"
        class="navbar-toggler navbar-toggler-right p-0"
        :class="{ toggled: $sidebar.showSidebar }"
        aria-expanded="false"
        @click="toggleSidebar"
      >
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
      <div class="navbar-minimize">
        <button
          class="btn btn-outline btn-fill btn-round btn-icon d-none d-lg-block"
          @click="minimizeSidebar"
        >
          <i
            :class="$sidebar.isMinimized ? 'fa fa-ellipsis-v' : 'fa fa-navicon'"
          ></i>
        </button>
      </div>
      <a class="navbar-brand mx-auto">{{ this.$route.name }}</a>
      <button
        style="opacity: 0"
        type="button"
        class="navbar-toggler navbar-toggler-right p-0"
        :class="{ toggled: $sidebar.showSidebar }"
        aria-expanded="false"
      >
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
      <button
        class="btn btn-secondary btn-sm m-0 d-sm-flex"
        type="button"
        @click="logout()"
      >
        <i class="nc-icon nc-button-power my-auto mr-0 mr-sm-1"></i>
        <span class="d-none d-sm-inline-block">Log out</span>
      </button>
    </div>
  </nav>
</template>
<script>
import Swal from "sweetalert2";

export default {
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    },

    logout() {
      Swal.fire({
        title: "แจ้งเตือน",
        html: "ยืนยันออกจากระบบ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-defalut",
        buttonsStyling: false,
      }).then(function (result) {
        if (result.isConfirmed) {
          $cookies.remove("token");
          $cookies.remove("refresh_token");
          window.location.href = "/login";
        }
      });
    },
  },
};
</script>
<style></style>
