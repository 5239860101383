<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <user-menu :title="title"></user-menu>
      <!-- <mobile-menu></mobile-menu> -->
      <template slot="links">
        <li
          :class="this.$route.path == '/asg' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/asg" class="nav-link">
            <i class="nc-icon nc-chart-bar-32"></i>
            <p>ALLSLOTGAME168</p>
          </router-link>
        </li>
        <li
          :class="this.$route.path == '/vip' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/vip" class="nav-link">
            <i class="nc-icon nc-chart-bar-32"></i>
            <p>ALLSLOTVIP</p>
          </router-link>
        </li>

        <li
          :class="this.$route.path == '/win' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/win" class="nav-link">
            <i class="nc-icon nc-chart-bar-32"></i>
            <p>ALLSLOTWIN</p>
          </router-link>
        </li>
        <li
          :class="this.$route.path == '/tuktuk888' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/tuktuk888" class="nav-link">
            <i class="nc-icon nc-chart-bar-32"></i>
            <p>TUKTUK888</p>
          </router-link>
        </li>
        <li
          :class="this.$route.path == '/oki888' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/oki888" class="nav-link">
            <i class="nc-icon nc-chart-bar-32"></i>
            <p>OKI888</p>
          </router-link>
        </li>
        <li
          :class="this.$route.path == '/ambet168' ? 'active' : ''"
          class="nav-item"
        >
          <router-link to="/ambet168" class="nav-link">
            <i class="nc-icon nc-chart-bar-32"></i>
            <p>AMBET168</p>
          </router-link>
        </li>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
// import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import axios from "axios";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    // MobileMenu,
    UserMenu,
  },
  data() {
    return {
      title: "All Dashboard",
      category: global.category,
      withdrawAlert: global.withdrawAlert,
      withdrawAmount: "",
      depositerrorAmount: "",

      audioContext: null,
      audioBuffer: null,
      audioSource: null,

      intervalWithdraw: null,
    };
  },

  methods: {
    async fetchAudioData() {
      try {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        this.audioContext = audioContext;

        // โหลดไฟล์เสียงของคุณที่นี่
        // const response = await fetch("/static/audio/notify.mp3");
        const response = await fetch("/static/audio/notify.mp3");
        const audioData = await response.arrayBuffer();

        audioContext.decodeAudioData(audioData, (buffer) => {
          this.audioBuffer = buffer;
        });
      } catch (error) {}
    },
    playAudio() {
      if (!this.audioBuffer || !this.audioContext) {
        // console.error("ยังไม่ได้โหลดข้อมูลเสียง.");
        return;
      }

      // ตรวจสอบว่าต้องการการสร้าง AudioContext และเรียก resume() หากจำเป็น
      if (!this.audioContext.state || this.audioContext.state === "suspended") {
        this.audioContext.resume().then(() => {
          this.internalPlayAudio();
        });
      } else {
        this.internalPlayAudio();
      }
    },
    internalPlayAudio() {
      if (this.audioSource) {
        this.audioSource.stop();
      }

      const audioSource = this.audioContext.createBufferSource();
      audioSource.buffer = this.audioBuffer;
      audioSource.connect(this.audioContext.destination);
      audioSource.start();

      this.audioSource = audioSource;
    },

    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("main-panel");

        docClasses.add("perfect-scrollbar-on");
      } else {
        docClasses.add("perfect-scrollbar-off");
      }
    },
    async fetchAmount() {
      await axios
        .get(`${this.$hostname}/amount?chk=1`)
        .then((res) => {
          this.withdrawAmount = res.data.withdrawAmount;
          this.depositerrorAmount = res.data.depositerrorAmount;

          if (res.data.withdrawAmount > 0) {
            this.playAudio();
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
  },
  created() {
    // this.fetchAudioData();
    // if (!this.intervalWithdraw) {
    //   this.fetchAmount();
    // }
    // this.intervalWithdraw = setInterval(() => {
    //   this.fetchAmount();
    // }, 15000);
  },
  beforeDestroy() {
    clearInterval(this.intervalWithdraw);

    if (this.audioSource) {
      this.audioSource.stop();
    }

    if (this.audioContext) {
      this.audioContext.close();
    }
  },
  mounted() {
    // this.title = this.$cookies.get("username");
  },
  watch: {
    $route(to, from) {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
